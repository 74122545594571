// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import {
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from "firebase/messaging";
import { generateCloudFunctionUrl } from "utils/helpers";
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const onAuthChanged = onAuthStateChanged;
export const db = getFirestore(app);
export const storage = getStorage(app);

//Initialize Cloud Functions
export const functions = getFunctions(app, "europe-west1");
export const generateUniqueVelvIdUrl = generateCloudFunctionUrl(
  "generateuniquevelvid"
);
export const createAccountForEmail = generateCloudFunctionUrl(
  "createaccountforemail"
);
export const getLinkForAccountWithId = generateCloudFunctionUrl(
  "getlinkforaccountwithid"
);
export const getAccountWithId = generateCloudFunctionUrl("getAccountWithId");
export const getLocalisedPriceFromProduct = generateCloudFunctionUrl(
  "getlocalisedpricefromproduct"
);
export const withdrawFundsForUserWithStripeIdAndFirebaseId =
  generateCloudFunctionUrl("withdrawfundsforuserwithstripeidandfirebaseid");
export const getSecondsRateAndBalanceForWallet = generateCloudFunctionUrl(
  "getsecondsrateandbalanceforwallet"
);
export const filterDropsOnGenre =
  generateCloudFunctionUrl("filterdropsongenre");

export const addVerifiedEmailToUsers = generateCloudFunctionUrl(
  "addverifiedemailtousers"
);

export const addVerifiedVelvtoUsers = generateCloudFunctionUrl(
  "addverifiedvelvtousers"
);


export const removeUser = generateCloudFunctionUrl("removeuser");

export const disableUser = generateCloudFunctionUrl("disableuser");

//Initialize Cloud Messaging
export const messaging = getMessaging(app);

export const generateToken = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted" && auth?.currentUser) {
      const messagingInstance = messaging;
      const token = await getToken(messagingInstance, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
      });

      const user = JSON.parse(localStorage.getItem("user"));
      if (token && token !== user?.fcmToken && auth?.currentUser) {
        const itemDoc = doc(db, "users", auth?.currentUser?.uid);
        await updateDoc(itemDoc, { fcmToken: token });
      }
    }
  } catch (error) {
    console.log("Error generating token!");
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging
      .then((messagingInstance) => {
        onMessage(messagingInstance, (payload) => {
          resolve(payload);
        });
      })
      .catch((error) => {
        console.error("Failed to get messaging instance:", error);
      });
  });
