import { lazy } from "react";
import { ROLES_LIST } from "./constants";

const SignIn = lazy(async () => await import("pages/SignIn/SignIn"));
const SignUp = lazy(async () => await import("pages/SignUp/SignUp"));
const NotFound = lazy(async () => await import("pages/NotFound/NotFound"));
const Home = lazy(async () => await import("pages/Home/Home"));
const Drop = lazy(async () => await import("pages/Drop/Drop"));
const MyPitches = lazy(async () => await import("pages/MyPitches/MyPitches"));
const Favorites = lazy(async () => await import("pages/Favorites/Favorites"));
const Genre = lazy(async () => await import("pages/Home/Genre/Genre"));
const Pitch = lazy(async () => await import("pages/Pitch/Pitch"));
const PitchLearn = lazy(
  async () => await import("pages/PitchLearn/PitchLearn")
);
const Account = lazy(async () => await import("pages/Account/Account"));
const AddDrop = lazy(async () => await import("pages/AddDrop/AddDrop"));
const SubmitDrop = lazy(
  async () => await import("pages/SubmitDrop/SubmitDrop")
);
const Wallet = lazy(async () => await import("pages/Wallet/Wallet"));
const PitchingView = lazy(
  async () => await import("pages/PitchingView/PitchingView")
);
const MyDrops = lazy(async () => await import("pages/MyDrops/MyDrops"));
const MyInbox = lazy(async () => await import("pages/MyInbox/MyInbox"));
const SuccessPayment = lazy(
  async () => await import("pages/PaymentStatus/SuccessPayment")
);
const CancelPayment = lazy(
  async () => await import("pages/PaymentStatus/CancelPayment")
);
const StripeVerification = lazy(
  async () => await import("pages/StripeStatus/StripeVerification")
);
const EditDrop = lazy(async () => await import("pages/EditDrop/EditDrop"));
const ForgotPassword = lazy(
  async () => await import("pages/ForgotPassword/ForgotPassword")
);
const Admin = lazy(async () => await import("pages/Admin/Admin"));
const AdminProducer = lazy(
  async () => await import("pages/Admin/AdminProducer")
);
const AdminUsers = lazy(async () => await import("pages/Admin/AdminUsers"));
const DropsReview = lazy(async () => await import("pages/Admin/DropsReview"));
const HomePage = lazy(
  async () => await import("landing/pages/HomePage/HomePage")
);
const HowToPage = lazy(
  async () => await import("landing/pages/HowToPage/HowToPage")
);
const TermsAndConditionsPage = lazy(
  async () =>
    await import("landing/pages/TermsAndConditionsPage/TermsAndConditionsPage")
);
const PrivacyAndCookies = lazy(
  async () =>
    await import("landing/components/PrivacyAndCookies/PrivacyAndCookies")
);
const About = lazy(async () => await import("landing/pages/About/About"));
const Contact = lazy(async () => await import("landing/pages/Contact/Contact"));
const SoundTrap = lazy(
  async () => await import("landing/pages/SoundTrap/SoundTrap")
);

export const routes = [
  {
    path: "*",
    name: "NotFound",
    exact: false,
    component: NotFound,
    protected: true,
  },
  {
    path: "/signup",
    name: "Login",
    exact: true,
    component: SignUp,
    protected: false,
  },

  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    exact: true,
    component: ForgotPassword,
    protected: false,
  },
  {
    path: "/login",
    name: "Login",
    exact: true,
    component: SignIn,
    protected: false,
  },
  {
    path: "/home",
    name: "LandingPage",
    exact: true,
    component: HomePage,
    protected: false,
  },
  {
    path: "/howto",
    name: "HowToPage",
    exact: true,
    component: HowToPage,
    protected: false,
  },
  {
    path: "/termsconditions",
    name: "TermsAndConditionsPage",
    exact: true,
    component: TermsAndConditionsPage,
    protected: false,
  },
  {
    path: "/privacycookies",
    name: "PrivacyAndCookies",
    exact: true,
    component: PrivacyAndCookies,
    protected: false,
  },
  {
    path: "/about",
    name: "About",
    exact: true,
    component: About,
    protected: false,
  },
  {
    path: "/contact",
    name: "Contact",
    exact: true,
    component: Contact,
    protected: false,
  },
  {
    path: "/soundtrap",
    name: "SoundTrap",
    exact: true,
    component: SoundTrap,
    protected: false,
  },
  {
    path: "/",
    name: "Home",
    exact: true,
    component: Home,
    protected: true,
    role: [
      ROLES_LIST.PRODUCER,
      ROLES_LIST.SONG_WRITER,
      ROLES_LIST.SONG_WRITER_PRODUCER,
      ROLES_LIST.ANR,
      ROLES_LIST.MANAGER,
      ROLES_LIST.SUPERVISOR,
      ROLES_LIST.CORPORATE,
      ROLES_LIST.SCREENER,
    ],
  },
  {
    path: "/drop/:id",
    name: "Drop",
    exact: true,
    component: Drop,
    protected: true,
    role: [
      ROLES_LIST.PRODUCER,
      ROLES_LIST.SONG_WRITER,
      ROLES_LIST.SONG_WRITER_PRODUCER,
      ROLES_LIST.ANR,
      ROLES_LIST.MANAGER,
      ROLES_LIST.SUPERVISOR,
      ROLES_LIST.CORPORATE,
    ],
  },
  {
    path: "/drop/:id/submit",
    name: "SubmitDrop",
    exact: true,
    component: SubmitDrop,
    protected: true,
    role: [
      ROLES_LIST.PRODUCER,
      ROLES_LIST.SONG_WRITER,
      ROLES_LIST.SONG_WRITER_PRODUCER,
    ],
  },
  {
    path: "/demo/:demoId/success",
    name: "SubmitDropSuccess",
    exact: true,
    component: SuccessPayment,
    protected: true,
    role: [
      ROLES_LIST.PRODUCER,
      ROLES_LIST.SONG_WRITER,
      ROLES_LIST.SONG_WRITER_PRODUCER,
    ],
  },
  {
    path: "/demo/:demoId/cancel",
    name: "SubmitDropSuccess",
    exact: true,
    component: CancelPayment,
    protected: true,
    role: [
      ROLES_LIST.PRODUCER,
      ROLES_LIST.SONG_WRITER,
      ROLES_LIST.SONG_WRITER_PRODUCER,
    ],
  },
  {
    path: "/drop/add",
    name: "AddDrop",
    exact: true,
    component: AddDrop,
    protected: true,
    role: [
      ROLES_LIST.ANR,
      ROLES_LIST.MANAGER,
      ROLES_LIST.SUPERVISOR,
      ROLES_LIST.CORPORATE,
    ],
  },
  {
    path: "/drop/edit/:id",
    name: "EditDrop",
    exact: true,
    component: EditDrop,
    protected: true,
    role: [
      ROLES_LIST.ANR,
      ROLES_LIST.MANAGER,
      ROLES_LIST.SUPERVISOR,
      ROLES_LIST.CORPORATE,
    ],
  },
  {
    path: "/drop/pitch/:id",
    name: "PitchingView",
    exact: true,
    component: PitchingView,
    protected: true,
    role: [
      ROLES_LIST.ANR,
      ROLES_LIST.MANAGER,
      ROLES_LIST.SUPERVISOR,
      ROLES_LIST.CORPORATE,
      ROLES_LIST.SCREENER,
    ],
  },
  {
    path: "/drop/mydrops",
    name: "MyDrops",
    exact: true,
    component: MyDrops,
    protected: true,
    role: [
      ROLES_LIST.ANR,
      ROLES_LIST.MANAGER,
      ROLES_LIST.SUPERVISOR,
      ROLES_LIST.CORPORATE,
    ],
  },
  {
    path: "/drop/myinbox",
    name: "MyInbox",
    exact: true,
    component: MyInbox,
    protected: true,
    role: [
      ROLES_LIST.ANR,
      ROLES_LIST.MANAGER,
      ROLES_LIST.SUPERVISOR,
      ROLES_LIST.CORPORATE,
    ],
  },
  {
    path: "/genre/:type",
    name: "Genre",
    exact: true,
    component: Genre,
    protected: true,
    role: [
      ROLES_LIST.ANR,
      ROLES_LIST.MANAGER,
      ROLES_LIST.SUPERVISOR,
      ROLES_LIST.CORPORATE,
      ROLES_LIST.PRODUCER,
      ROLES_LIST.SONG_WRITER,
      ROLES_LIST.SONG_WRITER_PRODUCER,
    ],
  },
  {
    path: "/favorites",
    name: "Favorites",
    exact: true,
    component: Favorites,
    protected: true,
    role: [
      ROLES_LIST.PRODUCER,
      ROLES_LIST.SONG_WRITER,
      ROLES_LIST.SONG_WRITER_PRODUCER,
    ],
  },
  {
    path: "/my-pitches",
    name: "MyPitches",
    exact: true,
    component: MyPitches,
    protected: true,
    role: [
      ROLES_LIST.PRODUCER,
      ROLES_LIST.SONG_WRITER,
      ROLES_LIST.SONG_WRITER_PRODUCER,
    ],
  },
  {
    path: "/my-pitches/learn",
    name: "PitchLearn",
    exact: true,
    component: PitchLearn,
    protected: true,
    role: [
      ROLES_LIST.PRODUCER,
      ROLES_LIST.SONG_WRITER,
      ROLES_LIST.SONG_WRITER_PRODUCER,
    ],
  },
  {
    path: "/my-pitches/:id/:pitchId",
    name: "Pitch",
    exact: true,
    component: Pitch,
    protected: true,
    role: [
      ROLES_LIST.PRODUCER,
      ROLES_LIST.SONG_WRITER,
      ROLES_LIST.SONG_WRITER_PRODUCER,
    ],
  },
  {
    path: "/wallet",
    name: "Wallet",
    exact: true,
    component: Wallet,
    protected: true,
    role: [ROLES_LIST.SCREENER],
  },
  {
    path: "/account",
    name: "Account",
    exact: true,
    component: Account,
    protected: true,
    role: [
      ROLES_LIST.PRODUCER,
      ROLES_LIST.SONG_WRITER,
      ROLES_LIST.SONG_WRITER_PRODUCER,
      ROLES_LIST.ANR,
      ROLES_LIST.MANAGER,
      ROLES_LIST.SUPERVISOR,
      ROLES_LIST.CORPORATE,
      ROLES_LIST.SCREENER,
    ],
  },
  {
    path: "/stripe/verification/:id",
    name: "StripeVerification",
    exact: true,
    component: StripeVerification,
    protected: true,
    role: [
      ROLES_LIST.ANR,
      ROLES_LIST.MANAGER,
      ROLES_LIST.SUPERVISOR,
      ROLES_LIST.CORPORATE,
      ROLES_LIST.SCREENER,
    ],
  },
  {
    path: "/admin",
    name: "Admin",
    exact: true,
    component: Admin,
    protected: true,
    role: [
      ROLES_LIST.ANR,
      ROLES_LIST.MANAGER,
      ROLES_LIST.SUPERVISOR,
      ROLES_LIST.CORPORATE,
    ],
  },
  {
    path: "/admin/:type",
    name: "AdminPages",
    exact: true,
    component: Admin,
    protected: true,
    role: [
      ROLES_LIST.ANR,
      ROLES_LIST.MANAGER,
      ROLES_LIST.SUPERVISOR,
      ROLES_LIST.CORPORATE,
    ],
  },
  {
    path: "/admin/producer",
    name: "AdminProducer",
    exact: true,
    component: AdminProducer,
    protected: true,
    role: [
      ROLES_LIST.ANR,
      ROLES_LIST.MANAGER,
      ROLES_LIST.SUPERVISOR,
      ROLES_LIST.CORPORATE,
    ],
  },
  {
    path: "/admin/songWriter",
    name: "AdminProducer",
    exact: true,
    component: AdminProducer,
    protected: true,
    role: [
      ROLES_LIST.ANR,
      ROLES_LIST.MANAGER,
      ROLES_LIST.SUPERVISOR,
      ROLES_LIST.CORPORATE,
    ],
  },
  {
    path: "/admin/songWriterProducer",
    name: "AdminProducer",
    exact: true,
    component: AdminProducer,
    protected: true,
    role: [
      ROLES_LIST.ANR,
      ROLES_LIST.MANAGER,
      ROLES_LIST.SUPERVISOR,
      ROLES_LIST.CORPORATE,
    ],
  },
  {
    path: "/admin/users",
    name: "AdminUsers",
    exact: true,
    component: AdminUsers,
    protected: true,
    role: [
      ROLES_LIST.ANR,
      ROLES_LIST.MANAGER,
      ROLES_LIST.SUPERVISOR,
      ROLES_LIST.CORPORATE,
    ],
  },
  {
    path: "/admin/review",
    name: "DropsReview",
    exact: true,
    component: DropsReview,
    protected: true,
    role: [
      ROLES_LIST.ANR,
      ROLES_LIST.MANAGER,
      ROLES_LIST.SUPERVISOR,
      ROLES_LIST.CORPORATE,
    ],
  },
];

export default routes;
