import { useEffect, useState } from "react";
import {
  getDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,
  collection,
  where,
  getDocs,
  limit,
} from "firebase/firestore";
import { Header } from "components/shared/Header/Header";
import { Footer } from "components/shared/Footer/Footer";
import { Sidebar } from "components/Sidebar/Sidebar";
import userIcon from "assets/icons/user-icon.svg";
import plusIcon from "assets/icons/plus-icon.svg";
import yellowStarIcon from "assets/icons/yellow-star-icon.svg";
import infoIcon from "assets/icons/info-icon.svg";
import redInfoIcon from "assets/icons/red-info-icon.svg";
import copyIcon from "assets/icons/copy-icon.svg";
import copySuccessIcon from "assets/icons/copy-success-icon.svg";
import closeIcon from "assets/icons/close-icon.svg";
import editIcon from "assets/icons/edit-icon.svg";
import grayStarIcon from "assets/icons/gray-star-icon.svg";
import { TooltipItem } from "components/shared/Tooltip/Tooltip";
import { auth, db, storage } from "config/firebase-config";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { PROFESSIONS_LIST_MAP, ROLES_LIST } from "utils/constants";
import { deleteCookie, getPropertyNameByValue } from "utils/helpers";
import arrowIcon from "assets/icons/arrow-icon.svg";
import { Tooltip } from "react-tooltip";

const COPY_MESSAGE_DURATION = 2000;

const Account = ({ notCompletedVerification, onClickBack }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [user, setUser] = useState(null);
  const [profilePic, setProfilePic] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [stars, setStars] = useState(1);
  const [copyMessage, setCopyMessage] = useState("");
  const [updatedUserImage, setUpdatedUserImage] = useState(0);
  const [myDemos, setMyDemos] = useState([]);
  const [myDrops, setMyDrops] = useState([]);
  const [canDeleteAccount, setCanDeleteAccount] = useState(true);
  /*  const queryS = collection(db, "users");

  const [docs, loading, error] = useCollectionData(queryS);*/

  const qDemos = query(
    collection(db, "demos"),
    where("userId", "==", auth?.currentUser?.uid)
  );

  const qDrops = query(
    collection(db, "drops"),
    where("userId", "==", auth?.currentUser?.uid)
  );

  const fetchDrops = async () => {
    try {
      const querySnapshotDrops = await getDocs(qDrops);
      let dropsListDrops = [];
      querySnapshotDrops.forEach((doc) => {
        dropsListDrops.push({ ...doc.data(), id: doc.id });
      });

      setMyDrops(dropsListDrops);
    } catch (error) {
      console.error("Error getting demos:", error);
    }
  };
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const querySnapshotDemos = await getDocs(qDemos);
      let demoListDemos = [];
      querySnapshotDemos.forEach((doc) => {
        demoListDemos.push({ id: doc.id, ...doc.data() });
      });
      setMyDemos(demoListDemos);

      let numOfHolds = 0;
      if (demoListDemos?.length > 0) {
        setStars(2);
        for (let i = 0; i < demoListDemos?.length; i++) {
          if (demoListDemos[i]?.result === "cut") {
            setStars(5);
            return;
          }
          if (demoListDemos[i]?.result === "hold") numOfHolds += 1;
        }
        if (stars < 5 && numOfHolds === 1) setStars(3);
        else if (stars < 5 && numOfHolds > 1) setStars(4);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error getting demos:", error);
    }
  };

  const checkIfAccountIsDeletable = async (myDrops) => {
    try {
      // If there is at least one demo to any of myDrops than account is not deletable.
      const dropIds = myDrops
        .map((drop) => drop?.id ?? "")
        .filter((id) => id !== "");
      if (dropIds.length === 0) {
        setCanDeleteAccount(true);
        return;
      }
      const qDemos = query(
        collection(db, "demos"),
        where("dropId", "in", dropIds),
        limit(1)
      );
      const querySnapshotDemos = await getDocs(qDemos);
      const hasNoMatchingDocs = querySnapshotDemos?.size === 0;
      setCanDeleteAccount(hasNoMatchingDocs);
    } catch (error) {
      console.error("Error checking if drop is deletable:", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchDrops();
  }, []);

  useEffect(() => {
    const checkAccountDeletionStatus = async () => {
      if (isUserANROrManager()) {
        setCanDeleteAccount(false);
        await checkIfAccountIsDeletable(myDrops);
      } else {
        setCanDeleteAccount(true);
      }
    };
    checkAccountDeletionStatus();
  }, [myDrops]);

  const getItem = async () => {
    const userRef = doc(db, "users", auth?.currentUser?.uid);

    try {
      const snapshot = await getDoc(userRef);
      setUser(snapshot.data());
      setProfilePic(snapshot.data()?.profileImage || userIcon);
    } catch (error) {
      console.log("Error", error);
    }
  };

  const updateItem = async (id, updatedItem) => {
    const itemDoc = doc(db, "users", id);
    return await updateDoc(itemDoc, updatedItem);
  };

  const deleteItem = async (id, collection) => {
    const itemDoc = doc(db, collection, id);
    return await deleteDoc(itemDoc);
  };

  const isUserProducerOrSongwriter = () => {
    return [
      ROLES_LIST.PRODUCER,
      ROLES_LIST.SONG_WRITER,
      ROLES_LIST.SONG_WRITER_PRODUCER,
    ].includes(user?.profession);
  };
  const isUserANROrManager = () => {
    return [
      ROLES_LIST.ANR,
      ROLES_LIST.MANAGER,
      ROLES_LIST.CORPORATE,
      ROLES_LIST.SUPERVISOR,
    ].includes(user?.profession);
  };

  const handleDeleteAccount = async () => {
    const user = auth?.currentUser;
    try {
      if (notCompletedVerification) {
        onClickBack();
      }
      if (isUserANROrManager() && myDrops?.length) {
        await Promise?.all(
          myDrops?.map(async (drop) => {
            try {
              await deleteItem(drop?.id, "drops");
            } catch (err) {
              console.error(`Failed to delete drop with ID ${drop?.id}:`, err);
            }
          })
        );
      }
      if (isUserProducerOrSongwriter() && myDemos?.length) {
        await Promise?.all(
          myDemos?.map(async (demo) => {
            try {
              await deleteItem(demo?.id, "demos");
            } catch (err) {
              console.error(`Failed to delete demo with ID ${demo?.id}:`, err);
            }
          })
        );
      }
      await deleteItem(user?.uid, "users");
      await user.delete();
      localStorage.removeItem("user");
      deleteCookie("user");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      uploadFile(file);
    }
  };

  const onImageChange = (profileImage) => {
    const localUser = JSON.parse(localStorage.getItem("user"));
    if (localUser) {
      localStorage.setItem(
        "user",
        JSON.stringify({ ...localUser, profileImage })
      );
    }
    updateItem(auth?.currentUser?.uid, { profileImage });
    setProfilePic(profileImage || userIcon);
    setShowProfileModal(false);
    setUpdatedUserImage((prev) => prev + 1);
  };

  const uploadFile = async (file) => {
    setIsLoading(true);
    const name = `${new Date().getTime()}-${file.name}`;
    const storageRef = ref(storage, name);

    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done!");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      (error) => {
        console.error("Upload Failed", error);
        setIsLoading(false);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          onImageChange(downloadURL);
        });
        setIsLoading(false);
      }
    );
  };

  const copyToClipBoard = async () => {
    try {
      await navigator.clipboard.writeText(user?.velvId || "");
      setCopyMessage("Copied!");
    } catch (err) {
      setCopyMessage("Failed to copy!");
    }
    setTimeout(() => {
      setCopyMessage("");
    }, COPY_MESSAGE_DURATION);
  };

  useEffect(() => {
    getItem();
  }, []);

  const isCompletedVerification = !notCompletedVerification;

  return (
    <div className="bg-[radial-gradient(circle_at_center_left,_var(--tw-gradient-stops))] from-[#00315F] to-25%">
      {isCompletedVerification && <Sidebar />}
      <div className="ml-4 lg:ml-32 mr-4 lg:mr-16 relative z-10 mb-16 lg:mb-28 overflow-hidden">
        {isCompletedVerification && (
          <Header updatedUserImage={updatedUserImage} />
        )}
        {!isCompletedVerification && (
          <button
            className="mt-8 flex gap-2 text-left text-white text-sm  cursor-pointer hover:text-white border border-solid border-white py-3 px-5 rounded-3xl top-5 right-5 items-center"
            onClick={onClickBack}
          >
            <img
              src={arrowIcon}
              alt="chevron"
              className="flex rotate-90 w-3 h-3 cursor-pointer"
            />
            Back
          </button>
        )}
        <div className="text-white text-3xl lg:text-4xl font-extrabold leading-[64px] mt-12">
          Profile
        </div>
        <div className="flex flex-col lg:flex-row items-center lg:items-start">
          <section className="flex justify-center">
            <div className="flex-col justify-start items-center lg:items-start gap-8 inline-flex">
              <div className="relative justify-center mt-6 items-center inline-flex rounded-full bg-purple flex-1 w-36 lg:w-[161.5px] h-36 lg:h-[161.5px]">
                {profilePic ? (
                  <>
                    {profilePic !== userIcon ? (
                      <img
                        src={profilePic}
                        alt="user"
                        className="w-36 lg:w-[161.5px] rounded-full h-36 lg:h-[161.5px] relative flex-col justify-start items-start flex object-cover"
                      />
                    ) : (
                      <div className="w-36 lg:w-[161.5px] rounded-full h-36 lg:h-[161.5px] object-cover bg-purple items-center justify-center flex">
                        <img
                          src={userIcon}
                          alt="avatar"
                          className="w-16 h-16"
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="skeleton w-16 h-16 lg:w-[161.5px] lg:h-[161.5px] bg-slate-900 rounded-full"></div>
                )}
                {isCompletedVerification && (
                  <button
                    className="cursor-pointer"
                    onClick={() => setShowProfileModal(true)}
                  >
                    <img
                      src={plusIcon}
                      alt="plus"
                      className="w-9 h-9 lg:flex lg:w-[42px] lg:h-[42px] absolute lg:bottom-1 lg:right-1 bottom-0 right-0 cursor-pointer"
                    />
                  </button>
                )}
              </div>
              {isUserProducerOrSongwriter() ? (
                <div className="flex lg:justify-end mt-10 w-full h-auto">
                  <section className="flex flex-col gap-3 mr-3">
                    <h5 className="text-gray-400 text-xs lg:text-lg font-extrabold uppercase leading-loose text-center lg:text-left w-full">
                      star status
                    </h5>
                    <div className="flex justify-start items-start gap-3">
                      {Array(stars)
                        .fill(0)
                        .map((item, index) => (
                          <img
                            key={index}
                            src={yellowStarIcon}
                            alt="star"
                            className="w-5 h-5"
                          />
                        ))}
                      {Array(5 - stars)
                        .fill(0)
                        .map((item, index) => (
                          <img
                            key={index}
                            src={grayStarIcon}
                            alt="star"
                            className="w-5 h-5"
                          />
                        ))}
                      <TooltipItem
                        icon={infoIcon}
                        place={"top-start"}
                        className="w-5 h-5 mt-[2px]"
                      >
                        <div className="flex-col justify-start items-start gap-1 inline-flex">
                          <div className="w-full justify-start items-start gap-3 inline-flex">
                            <img
                              src={yellowStarIcon}
                              alt="star"
                              className="w-5 h-5 relative"
                            />
                            <div className="w-full">
                              <span className="text-white text-xs font-normal  leading-tight">
                                Signing up on{" "}
                              </span>
                              <span className="text-white text-xs font-bold  leading-tight">
                                Velv
                              </span>
                            </div>
                          </div>
                          <div className="justify-start items-start gap-3 inline-flex">
                            <img
                              src={yellowStarIcon}
                              alt="star"
                              className="w-5 h-5 relative"
                            />
                            <img
                              src={yellowStarIcon}
                              alt="star"
                              className="w-5 h-5 relative"
                            />
                            <div className="w-full">
                              <span className="text-white text-xs font-normal  leading-tight">
                                Submitting your first{" "}
                              </span>
                              <span className="text-white text-xs font-bold  leading-tight">
                                Demo
                              </span>
                            </div>
                          </div>
                          <div className="justify-start items-start gap-3 inline-flex">
                            <img
                              src={yellowStarIcon}
                              alt="star"
                              className="w-5 h-5 relative"
                            />
                            <img
                              src={yellowStarIcon}
                              alt="star"
                              className="w-5 h-5 relative"
                            />
                            <img
                              src={yellowStarIcon}
                              alt="star"
                              className="w-5 h-5 relative"
                            />
                            <div className="w-full">
                              <span className="text-white text-xs font-normal  leading-tight">
                                Receiving your first{" "}
                              </span>
                              <span className="text-white text-xs font-bold  leading-tight">
                                Hold
                              </span>
                            </div>
                          </div>
                          <div className="w-full justify-start items-start gap-3 inline-flex">
                            <img
                              src={yellowStarIcon}
                              alt="star"
                              className="w-5 h-5 relative"
                            />
                            <img
                              src={yellowStarIcon}
                              alt="star"
                              className="w-5 h-5 relative"
                            />
                            <img
                              src={yellowStarIcon}
                              alt="star"
                              className="w-5 h-5 relative"
                            />
                            <img
                              src={yellowStarIcon}
                              alt="star"
                              className="w-5 h-5 relative"
                            />
                            <div className="w-full">
                              <span className="text-white text-xs font-normal  leading-tight">
                                Achieving 3{" "}
                              </span>
                              <span className="text-white text-xs font-bold  leading-tight">
                                Hold
                              </span>
                            </div>
                          </div>
                          <div className="w-full justify-start items-start gap-3 inline-flex">
                            <img
                              src={yellowStarIcon}
                              alt="star"
                              className="w-5 h-5 relative"
                            />
                            <img
                              src={yellowStarIcon}
                              alt="star"
                              className="w-5 h-5 relative"
                            />
                            <img
                              src={yellowStarIcon}
                              alt="star"
                              className="w-5 h-5 relative"
                            />
                            <img
                              src={yellowStarIcon}
                              alt="star"
                              className="w-5 h-5 relative"
                            />
                            <img
                              src={yellowStarIcon}
                              alt="star"
                              className="w-5 h-5 relative"
                            />
                            <div className="w-full">
                              <span className="text-white text-xs font-normal  leading-tight">
                                Receiving a{" "}
                              </span>
                              <span className="text-white text-xs font-bold  leading-tight">
                                Cut
                              </span>
                            </div>
                          </div>
                        </div>
                      </TooltipItem>
                    </div>
                  </section>
                </div>
              ) : null}
            </div>
          </section>
          <section className="mt-8 grid grid-cols-1 lg:grid-cols-1 gap-8 lg:gap-0 lg:ml-40">
            <div className="flex flex-col justify-start w-full gap-3">
              <div className="flex justify-start items-start">
                <div className="text-gray-400 text-xs lg:text-xl font-extrabold capitalize leading-loose w-24 lg:w-40">
                  role
                </div>
                <div className="text-slate-500 text-sm lg:text-xl font-normal leading-relaxed">
                  {getPropertyNameByValue(
                    user?.profession,
                    PROFESSIONS_LIST_MAP
                  ) || "~"}
                </div>
              </div>

              <div className="flex justify-start items-center">
                <div className="text-gray-400 text-xs lg:text-xl font-extrabold capitalize leading-loose w-24 lg:w-40">
                  name
                </div>
                <div className="text-slate-500 text-sm lg:text-xl font-normal leading-relaxed">
                  {user?.name || "-"}
                </div>
              </div>
              {isCompletedVerification && (
                <div className="flex items-center">
                  <h5 className="text-gray-400 text-xs lg:text-xl font-extrabold capitalize leading-loose w-24 lg:w-40">
                    velv id
                  </h5>
                  <div className="relative flex gap-2 text-slate-500 text-sm lg:text-xl font-normal leading-relaxed">
                    {user?.velvId || "-"}
                    <img
                      src={copyIcon}
                      alt="copy"
                      className="w-[16px] h-[16px] relative cursor-pointer"
                      onClick={copyToClipBoard}
                    />
                    {copyMessage && (
                      <div className="absolute flex items-center gap-1 top-[-24px] right-[-44px] z-50 bg-[#000] h-[26px]">
                        <span className="font-Mulish font-bold text-purple text-xs leading-[13.81px]">
                          {copyMessage}
                        </span>
                        <img
                          src={copySuccessIcon}
                          alt="copySuccess"
                          className="w-4 h-4"
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className="flex justify-start items-center">
                <div className="text-gray-400 text-xs lg:text-xl font-extrabold capitalize leading-loose w-24 lg:w-40">
                  email
                </div>
                <div className="text-slate-500 text-sm lg:text-xl font-normal leading-relaxed">
                  {user?.email || "-"}
                </div>
              </div>
            </div>
          </section>
        </div>

        <div className="h-px bg-zinc-700 mt-24 mb-10" />
        <div className="flex justify-between flex-col lg:flex-row">
          <button
            className={`tooltipAnchor px-6 py-3 lg:bg-red-500 rounded-[90px] lg:justify-center lg:items-center gap-3 mx-auto lg:mx-0 w-fit ${
              canDeleteAccount ? "" : "opacity-50"
            }`}
            onClick={() => setShowDeleteModal(true)}
            disabled={!canDeleteAccount}
          >
            <div className="text-center text-red-500 lg:text-gray-50 text-base font-bold leading-none w-40 flex justify-center">
              Delete Account
            </div>
          </button>
          {!canDeleteAccount && (
            <Tooltip anchorSelect={`.tooltipAnchor`}>
              <div
                className={`text-left px-2 py-1 text-white text-sm rounded-md right-auto lg:-right-1`}
              >
                <p className="font-bold text-xs text-center">
                  Account can not be deleted!
                </p>
                <p className="max-w-[240px] text-xs mt-1 leading-xl">
                  You have submissions linked to one or more of your published
                  Drops. Please contact support@velvmusic.com for further
                  assistance.
                </p>
              </div>
            </Tooltip>
          )}
          <button className="text-purple text-sm lg:text-xl font-extrabold leading-7 mt-10 lg:mt-0">
            Customer Support
            <p className="text-white text-xs">support@velvmusic.com</p>
          </button>
        </div>
      </div>
      {showDeleteModal ? (
        <DeleteAccountModal
          setShowDeleteModal={setShowDeleteModal}
          handleDeleteAccount={handleDeleteAccount}
        />
      ) : null}
      {showProfileModal ? (
        <ProfilePictureModal
          setShowProfileModal={setShowProfileModal}
          profileImage={profilePic}
          handleFileChange={handleFileChange}
          isLoading={isLoading}
          removeImage={() => onImageChange("")}
        />
      ) : null}
      <Footer />
    </div>
  );
};

export default Account;

function DeleteAccountModal({ setShowDeleteModal, handleDeleteAccount }) {
  return (
    <div className="fixed top-0 left-0 flex justify-center items-center bg-zinc-800 bg-opacity-80 w-screen h-screen z-40">
      <div className="lg:w-[448px] w-4/5 h-[436px] p-8 bg-zinc-900 rounded-[20px] shadow flex-col justify-center items-center gap-8 inline-flex">
        <div className="self-stretch justify-start items-center inline-flex">
          <div className="grow shrink basis-0 text-gray-50 text-2xl lg:text-[32px] font-bold  leading-10">
            Delete Account
          </div>
          <button
            className="p-1 lg:p-2 rounded-[48px] border-2 border-zinc-700 justify-center items-center gap-2.5 flex"
            onClick={() => setShowDeleteModal(false)}
          >
            <img
              src={closeIcon}
              alt="close"
              className="w-4 lg:w-5 h-4 lg:h-5 p-1 relative"
            />
          </button>
        </div>
        <div className="self-stretch justify-start items-center gap-5 inline-flex">
          <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
            <div className="self-stretch text-gray-50 text-base font-medium leading-normal">
              Are you sure you want to delete your account?
            </div>
          </div>
        </div>
        <div className="self-stretch p-6 bg-rose-500 bg-opacity-10 rounded-lg justify-between items-center inline-flex">
          <div className="h-[60px] justify-start items-center gap-4 flex">
            <img src={redInfoIcon} alt="info" className="w-8 h-8" />
            <div className="w-[212px] flex-col justify-start items-center gap-px inline-flex">
              <div className="max-w-[212px] text-rose-500 text-sm font-medium  leading-tight">
                This action is irreversible, and all your data will be
                permanently removed.
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex-col justify-start items-start gap-2 flex">
          <button
            className="self-stretch px-6 py-3 rounded-[90px] border-2 border-zinc-700 justify-center items-center gap-3 inline-flex cursor-pointer"
            onClick={() => setShowDeleteModal(false)}
          >
            <div className="text-center text-gray-50 text-base font-bold  leading-none">
              Cancel
            </div>
          </button>
          <button
            className="self-stretch px-6 py-3 bg-red-500 rounded-[90px] justify-center items-center gap-3 inline-flex cursor-pointer"
            onClick={handleDeleteAccount}
          >
            <div className="text-center text-gray-50 text-base font-bold  leading-none">
              Delete My Account
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

function ProfilePictureModal({
  setShowProfileModal,
  profileImage,
  handleFileChange,
  removeImage,
  isLoading,
}) {
  return (
    <div className="fixed top-0 left-0 flex justify-center items-center bg-zinc-800 bg-opacity-80 w-screen h-screen z-40">
      <div className="lg:w-[448px] w-4/5 h-[432px] p-8 bg-zinc-900 rounded-[20px] shadow flex-col justify-center items-center gap-8 inline-flex">
        <div className="self-stretch justify-start items-center inline-flex">
          <div className="grow shrink basis-0 text-gray-50 text-2xl lg:text-[32px] font-bold  leading-10">
            Profile picture
          </div>
          <button
            className="p-1 lg:p-2 rounded-[48px] border-2 border-zinc-700 justify-center items-center gap-2.5 flex cursor-pointer"
            onClick={() => setShowProfileModal(false)}
          >
            <img
              src={closeIcon}
              alt="close"
              className="w-4 h-4 lg:w-5 lg:h-5 p-1 relative"
            />
          </button>
        </div>
        <div className="relative bg-purple rounded-full justify-center items-center inline-flex w-40 h-40">
          {profileImage ? (
            <>
              {profileImage !== userIcon ? (
                <img
                  src={profileImage}
                  alt="user"
                  className="w-full h-full object-cover rounded-full"
                />
              ) : (
                <div className="w-full h-full object-cover rounded-full bg-purple items-center justify-center flex">
                  <img src={userIcon} alt="avatar" className="w-20 h-20" />
                </div>
              )}
            </>
          ) : (
            <img src={userIcon} alt="user" className="w-16 h-16 relative" />
          )}

          <button
            className="cursor-pointer"
            onClick={() => setShowProfileModal(true)}
          ></button>
        </div>
        <div className="self-stretch flex-col justify-start items-start gap-2 flex">
          <input
            type="file"
            id="file"
            accept="image/*"
            disabled={isLoading}
            className="hidden"
            onChange={handleFileChange}
          />
          <label
            htmlFor="file"
            disabled={isLoading}
            className={`self-stretch px-6 py-3 bg-purple rounded-[90px] justify-center items-center gap-3 inline-flex cursor-pointer ${
              isLoading && "cursor-not-allowed bg-violet-900"
            }`}
          >
            <img src={editIcon} alt="close" className="w-4 h-4 relative" />
            <div className="text-gray-50 text-base font-bold  leading-none">
              Change
            </div>
          </label>
          <button
            onClick={removeImage}
            className="self-stretch px-6 py-3 rounded-[90px] border-2 border-zinc-700 justify-center items-center gap-3 inline-flex cursor-pointer"
          >
            <img
              src={closeIcon}
              alt="close"
              className="w-4 h-4 p-[2px] relative"
            />
            <div className="text-gray-50 text-base font-bold  leading-none">
              Remove
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}
